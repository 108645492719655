import { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import {  viewIs } from "../common/utils"

import "../css/top.css";

const Header = () => {
    const [ inSwiper, setSwiper ] = useState(null)
    const [initIndex] = useState(0);
    const [activeIndex,setActive] = useState(0)
    const [videoMuted,setMuted] = useState(true)
    const videoRef = useRef(null);
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"> </span>';
        },
    };
    useEffect(()=>{
        const videoElement = videoRef.current;
        if (!viewIs('section-top')||!videoElement) return;
        if(activeIndex==0){
            videoElement.play()
        }
        videoElement.addEventListener('ended', function () { //结束
            inSwiper.slideTo(1,300)
        }, false);
    })
    function changeMuted(bl){
        const videoElement = videoRef.current;
        if(!videoElement) return
        videoElement.muted = bl;
        setMuted(bl)
    }
    return<section className="top_wapper" id='section-top'>
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            initialSlide={initIndex}
            pagination={pagination} 
            modules={[Pagination]}
            onSlideChange={(swipe) => {
                setActive(swipe.activeIndex)
                const videoElement = videoRef.current;
                if(swipe.activeIndex===0){
                    videoElement.play()
                    // videoElement.muted = false;
                    // setMuted(false)
                }else{
                    videoElement.pause()
                }
            }}
            onSwiper={(swiper) =>{
                setSwiper(swiper)
                const videoElement = videoRef.current;
                videoElement.play()
                console.log(videoElement.muted,'mutedmutedmutedmutedmuted');
            }}
        >
            <SwiperSlide>
                <div className='flex-wrap top_video' >
                    <video ref={videoRef} muted autoPlay={true} loop="" webkit-playsinline="true" playsInline src="https://jhzp-zheer-files.oss-cn-beijing.aliyuncs.com/banner.mp4" id="top-video"></video>
                    {
                        videoMuted?
                        <i className='muted_icon disable' onClick={()=>changeMuted(false)} />
                        :
                        <i className='muted_icon canable' onClick={()=>changeMuted(true)} />
                    }
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='flex-wrap top_img'>
                    <div className='w1200 flex-col justify-end align-center'>
                        <div className='top_text justify-start'>
                        杭州九划网络科技有限公司成立于2016年，总部位于国家信息化试点城市——杭州，
                        是一家全国领先的医学教育行业信息化服务解决方案提供商，公司提供全方位的医学教育信息化方案、软件产品和学习平台。
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </section>
}

export default Header;