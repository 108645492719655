import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

(function(w, d, m) {
  function r() {
      var t = 100,
      o = 750,
      e = d.documentElement.clientWidth || w.innerWidth,
      n = Math.max(Math.min(e, 480), 320),
      h = 50;
      if(480==n){
        h = 100
      }
      if(320 >= n){
        h = Math.floor((n / o) * t * 0.99)
      }
      if(n > 320 && 362 >= n){
        h = Math.floor((n / o) * t * 1)
      }
      if(n > 362 && 375 >= n){
        h = Math.floor((n / o) * t * 1)
      }
      if(n > 375&&n<480){
        h = Math.floor((n / o) * t * 0.97)
      }
      d.querySelector("html").style.fontSize = h + "px";
  }
  r();
  w.onresize = function() {
      r();
  };
})(window, document, Math);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
