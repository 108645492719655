import { useState, useEffect, useRef } from 'react';
import HonorCount from './HonorCount'
import HonorCard from './HonorCard'
import ImageList from './ImageList'
import poster from '../../images/honor/poster.jpg'
import part3title from '../../images/part3-title.png'

import "../../css/honor.css";

const Honor = () =>{
    const [statusVideo,setVideo] = useState(false)
    const videoRef = useRef(null);
    useEffect(()=>{
        const videoElement = videoRef.current;
        if (!videoElement) return;
    })
    function play(){
        const videoElement = videoRef.current;
        if (!videoElement) return;
        videoElement.play()
        setVideo(true)
    }
    return <section className='honor-wapper'>
        <ImageList/>
        <div className='vw_with'>
            <div className='h50'/>
            <div className='backgroundimg'>
                <div className='w1200 j-w-center' id='section-honor'>
                    <HonorCount></HonorCount>
                </div>
            </div>
            <div className='w1200 j-w-center'>
                <div className='h50'/>
                <img className='part3_title' src={part3title} />
                <HonorCard></HonorCard>
            </div>
            <div className='w1200 j-w-center'>
                <div className='h50'/>
                <div className='video_replay w1200 j-w-h-center'>
                    {
                        !statusVideo&&<i className='play_ico' id='btn_play' onClick={()=>play()} />
                    }
                    <video ref={videoRef} poster={poster} controls={statusVideo} webkit-playsinline="true" playsInline src='https://jhzp-zheer-files.oss-cn-beijing.aliyuncs.com/playback.mp4' id="replay-video"></video>
                </div>
            </div>
        </div>
    </section>
}

export default Honor;