
const Wave = () => {
    
    return <div className="container">
        <div className="wave">
            <div className="center_s"></div>
        {
            [1,2,3,4,5,6,7,8,9,10].map(i=>{
            return <span style={{'--i':`${i}`}} key={i}></span>
            })
        }
        </div>
    </div>
}

export default Wave;