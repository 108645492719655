import { useState, useContext, useEffect } from 'react';
import { scrollToEl } from '../../common/utils'
import { GlobalContext } from '../../globalContext';
import "../../css/product.css";
const LeftNav = () => {
    const [tab,setTab] = useState()
    const options = useContext(GlobalContext);
    const { currtentDom } = options

    const domList = ['resident','medical','object','compet','cloud','clinical']
    const changeTab = (i)=>{
        scrollToEl(`section-${domList[i]}`)
    }
    useEffect(()=>{
        setTab(domList.indexOf(currtentDom))
    })
    return <div className={`group_206 flex-col ${domList.indexOf(currtentDom)>-1?'opt':'no_opt'}`}>
    <div className={`text-wrapper_11 flex-col ${tab==0?'active':''}`} onClick={()=>changeTab(0)}>
      <span className="paragraph_1">住院医师规范化<br/>管理系统</span>
    </div>
    <div className={`text-wrapper_11 flex-col ${tab==1?'active':''}`} onClick={()=>changeTab(1)}>
      <span className="paragraph_1">医学模拟中心<br/>管理系统</span>
    </div>
    <div className={`text-wrapper_11 flex-col ${tab==2?'active':''}`} onClick={()=>changeTab(2)}>
      <span className="paragraph_1">OSCE考试系统</span>
    </div>
    <div className={`text-wrapper_11 flex-col ${tab==3?'active':''}`} onClick={()=>changeTab(3)}>
      <span className="paragraph_1">医学竞赛系统</span>
    </div>
    <div className={`text-wrapper_11 flex-col ${tab==4?'active':''}`} onClick={()=>changeTab(4)}>
      <span className="paragraph_1">医学云课堂</span>
    </div>
    <div className={`text-wrapper_11 flex-col ${tab==5?'active':''}`} onClick={()=>changeTab(5)}>
      <span className="paragraph_1">临床思维训练系统</span>
    </div>
  </div>
}

export default LeftNav;