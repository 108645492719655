export const scrollToEl = (anchorId,pos='end')=>{
    let scrollElement = document.getElementById('root');    // 对应id的滚动容器
    let anchorElement = document.getElementById(anchorId);  // 需要定位看到的锚点元素
    if(scrollElement&&anchorElement) {
        anchorElement.scrollIntoView({behavior: "smooth", block: pos, inline: "nearest"});
        // anchorElement.scrollIntoView(false);
    }
}
// export const viewIs = (anchorId,h=0)=>{

//     //获取屏幕高度
//     var windowTop = window.innerHeight;

//     let footerEl = document.getElementById(anchorId);
//     if(!footerEl)return false
//     let pos = footerEl.getBoundingClientRect();
    
//     let p = pos.top - windowTop - footerEl.clientHeight - h;
//     console.log( pos.top,windowTop, footerEl.clientHeight,p);
//     if(p<0){
//         return true
//     }else{
//         return false
//     }
// }
export const viewIs = (anchorId,h=0)=>{
    var banner = document.querySelector(`#${anchorId}`);
    if(!banner)return false
    var scope = (banner.offsetHeight * (100 - 80)) / 100; //广告栏可出线范围大小
    var cH = document.documentElement.clientHeight; //当前页面可见区域高度
    var tY = banner.getBoundingClientRect().top; //广告栏顶部到可见区域顶端的高度
    var bY = banner.getBoundingClientRect().bottom; //广告栏底部到可见区域顶端的高度
    // 需要考虑两种可能   当tY小于-scope则代表不在窗口展示   当bY大于cH+scope则代表不在窗口展示
    if (tY < -scope || bY > cH + scope) {
        return false
    } else {
        return true
    }
}
export const debounce = (fn, wait)=>{
    let timeout
    return function () {
      let context = this,
      args = arguments
      clearTimeout(timeout)
  
      timeout = setTimeout(function () {
        fn.apply(context, args)
      }, wait)
    }
}
