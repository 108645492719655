import logo from '../images/footer-logo.svg'
const Footer = () => {
    return<footer className="vw_with footer_wapper" id='section-footer'>
        <div className='w1200 j-w-h-between justify-between align-center is-pc'>
          <div className="block_53 flex-row align-center justify-center">
            <a className="text-group_245 flex-row align-center" href='https://m.zhipin.com/gongsir/b02d239a6668c97803Vz2tq4GQ~~.html?ka=job-detail-company_custompage' target='_blank'>
              <img className='footerlogo align-center' src={logo}/>
              <div className='flex-col justify-between'>
                <span className="text_131">加入我们&nbsp;&gt;</span>
                <span className="text_130">Contact&nbsp;Us</span>
              </div>
            </a>
          </div>
          <div className="section_112 flex-col">
              <span className="text_129">联系地址：</span>
              <div className="text-group_244 flex-col">
                <span className="text_130">浙江省杭州市萧山区</span>
                <span className="text_130">宁围街道详腾财富中心2幢</span>
              </div>
          </div>
          <div className="section_112 flex-col">
              <span className="text_129">联系方式：</span>
              <div className="text-group_244 flex-col">
                <span className="text_130">电话：13588070789 | 15306533592</span>
                <span className="text_130">邮箱：postmaster@9linetech.com</span>
              </div>
          </div>
          <div className="section_112 flex-col">
              <span className="text_129">工作时间：</span>
              <div className="text-group_244 flex-col justify-between">
                <span className="text_130">上午&nbsp;9:00-12:00</span>
                <span className="text_130">下午&nbsp;13:30-17:30</span>
              </div>
          </div>
        </div>
        <div className='w1200 j-w-h-between justify-between align-center is-mobile'>
          <div className="section_112 flex-col">
              <span className="text_129">工作时间：</span>
              <div className="text-group_244 flex-col justify-between">
                <span className="text_130">上午&nbsp;9:00-12:00</span>
                <span className="text_130">下午&nbsp;13:30-17:30</span>
              </div>
          </div>
          <div className="block_53 flex-row align-center justify-center">
            <a className="text-group_245 flex-row align-center" href='https://m.zhipin.com/gongsir/b02d239a6668c97803Vz2tq4GQ~~.html?ka=job-detail-company_custompage' target='_blank'>
              <img className='footerlogo align-center' src={logo}/>
              <div className='flex-col justify-between'>
                <span className="text_131">加入我们&nbsp;&gt;</span>
                <span className="text_130">Contact&nbsp;Us</span>
              </div>
            </a>
          </div>
          <div className="section_112 flex-col">
              <span className="text_129">联系地址：</span>
              <div className="text-group_244 text-group_246 flex-col">
                <span className="text_130">浙江省杭州市萧山区</span>
                <span className="text_130">宁围街道详腾财富中心2幢</span>
              </div>
          </div>
          <div className="section_112 flex-col">
              <span className="text_129">联系方式：</span>
              <div className="text-group_244 flex-col text-group_246">
                <span className="text_130">电话：13588070789 | 15306533592</span>
                <span className="text_130">邮箱：postmaster@9linetech.com</span>
              </div>
          </div>
        </div>
        <div className='text_138'></div>
        <div className='text_139'>
          © 2022 杭州九划网络科技有限公司 版权所有
        </div>
    </footer>
}

export default Footer;