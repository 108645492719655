import part2Title from '../../images/part2-title.png'

const imageList = () => {
    const list1 = []
    for (let index = 1; index < 39; index++) {
        const element = require(`../../images/honor/logo/part2-logo${index}.svg`);
        list1.push(element)
    }

    return <div className='w1420 j-w-center'>
        <div className='h50'/>
        <img className='part2_title' src={part2Title}>
        </img>
        <p className='wapper_text'>
            为医学教育提供全方位的信息化方案、软件产品和学习平台
        </p>
        <div className='group_image j-w-h-center'>
            {
                list1.map((item,i)=>{
                    if(i>8)return null
                    return<div className='image_wapper' key={i}>
                        <img className='image_item' src={item}></img>
                </div>
                })
            }
        </div>
        <div className='group_image j-w-h-center'>
            {
                list1.map((item,i)=>{
                    if(i>18||i<9)return null
                    return<div className='image_wapper' key={i}>
                        <img className='image_item' src={item}></img>
                </div>
                })
            }
        </div>
        <div className='group_image j-w-h-center'>
            {
                list1.map((item,i)=>{
                    if(i>27||i<19)return null
                    return<div className='image_wapper' key={i}>
                        <img className='image_item' src={item}></img>
                </div>
                })
            }
        </div>
        <div className='group_image j-w-h-center'>
            {
                list1.map((item,i)=>{
                    if(i<28)return null
                    return<div className='image_wapper' key={i}>
                        <img className='image_item' src={item}></img>
                </div>
                })
            }
        </div>
    </div>
}

export default imageList;