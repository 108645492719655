import { useState, useContext, useEffect } from 'react';
import "../css/header.css";
import { scrollToEl } from '../common/utils';
import { GlobalContext } from '../globalContext';

const Header = () => {
    const [tab,setTab] = useState(0);
    const options = useContext(GlobalContext);
    const { currtentDom,scrollPosition } = options
    
    const changeTab = (i,id) => {
        const type = i===0?'start':'center';
        scrollToEl(id,type)
    }
    const domList = ['top','honor','product','footer','resident','medical','object','compet','cloud','clinical']
    useEffect(()=>{
        let i = domList.indexOf(currtentDom)>3?2:domList.indexOf(currtentDom)
        setTab(i)
    })
    return<header className={`header_wapper ${scrollPosition>1000?'dark':'white'} `}>
        <div className='w1200 j-w-h-between'>
            <h1>
                <a className='top_logo j-w-h-center' href='//www.9linetech.com' rel="noreferrer">
                    {/* <img src={logo2}></img> */}
                </a>
            </h1>
            <ul className={`top_tab_list j-flex-row active_${tab}`}>
                <li className='tab_wapper home_tab j-w-h-center' onClick={()=>{changeTab(0,'section-top')}}>
                    <span className='tab_text'>首页</span>
                </li>
                <li className='tab_wapper honor_tab j-w-h-center' onClick={()=>{changeTab(1,'section-honor')}}>
                    <span className='tab_text'>荣誉殿堂</span>
                </li>
                <li className='tab_wapper bux_tab j-w-h-center' onClick={()=>{changeTab(2,'section-product')}}>
                    <span className='tab_text'>产品体系</span>
                </li>
                <li className='tab_wapper add_tab j-w-h-center' onClick={()=>{changeTab(3,'section-footer')}}>
                    <span className='tab_text'>加入我们</span>
                </li>
            </ul>
        </div>
    </header>
}

export default Header;