import { useState, useContext } from 'react';
import axios from 'axios'
import qs from 'qs'
import Modal from '../modal';
import Wave from '../Wave';
import { GlobalContext } from '../../globalContext';
const sumbitObj = {}
const SystemCom = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalOptions, setOptions] = useState(sumbitObj);
    const options = useContext(GlobalContext);
    const { currtentDom } = options

    const _handleChange = (value,key)=>{
        modalOptions[key] = value;
        setOptions(modalOptions)
    }
    const modalConfig = {
        visible: modalVisible,
        closeModal: () => {
            setModalVisible(false);
        }
    };
    const url = 'http://43.143.191.41/api/submit_info'
    const submit = ()=>{
        axios.post(url, qs.stringify(modalOptions))
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        setModalVisible(false)
    }
    const ww = document.documentElement.clientWidth || window.innerWidth;
    const modalChildren = (
        <div className="dialog">
            <div className='dialog_top'>
                <span onClick={() => setModalVisible(false)} className="closeBtn"></span>
            </div>
            <div className='form_table'>
                <h1 className='form_title'>填写信息</h1>
                <div className='form_item align-center justify-center'>
                    <div className='form_left justify-end'>
                        <span className='red'>*</span>
                        <label className='form_label'>单位名称</label>
                    </div>
                    <input className='form_input' value={modalOptions.company_name} placeholder={`${ww>767?'必填':'单位名称'}`} onChange={(e) => _handleChange(e.target.value,'company_name')}/>
                </div>
                <div className='form_item align-center justify-center'>
                    <div className='form_left justify-end'>
                        <span className='red'>*</span>
                        <label className='form_label'>联系方式</label>
                    </div>
                    <input className='form_input' value={modalOptions.phone} placeholder={`${ww>767?'必填':'联系方式'}`}  onChange={(e) => _handleChange(e.target.value,'phone')}/>
                </div>
                <div className='form_item align-center justify-center'>
                    <div className='form_left justify-end'>
                        <span className='red'>*</span>
                        <label className='form_label'>姓名</label>
                    </div>
                    <input className='form_input' value={modalOptions.name} placeholder={`${ww>767?'必填':'姓名'}`}  onChange={(e) => _handleChange(e.target.value,'name')}/>
                </div>
                <div className='form_item justify-center'>
                    <div className='form_left justify-end'>
                        <label className='form_label'>备注</label>
                    </div>
                    <textarea className='form_input form_text' value={modalOptions.note} placeholder={`${ww>767?'请输入备注，非必填项':'备注（非必填）'}`} onChange={(e) => _handleChange(e.target.value,'note')}/>
                </div>
            </div>
            <div className='btn_wapper justify-center'>
                <div className='cansel_btn' onClick={() => setModalVisible(false)}>取消</div>
                <div className='submit_btn cansel_btn' onClick={() => submit()}>确定</div>
            </div>
        </div>
    );
    const domList = ['resident','medical','object','compet','cloud','clinical']
    return <section className='system-wapper' id='setction-1'>
        <div className='vw_with group_160 group_21'>
            <div className='w1200 j-w-center'>
                <div className='h50'/>
                <h1 className='wapper_title'>
                    住院医师规范化管理系统
                </h1>
                <p className='wapper_text'>
                    Resident standardization training Management system
                </p>
                <section className='section_201 j-w-h-between' id='section-resident'>
                    <div className='box_11 flex-col box_101'></div>
                    <div className={`box_12 flex-col box_102 ${domList.indexOf(currtentDom)==0?'up':'down'}`}>
                        <Wave></Wave>
                        <div className='group_54 flex-col'>
                            <span className="text_123 flex-col">通过对规范化培训中医师的全过程管理，
                            能够智能化的对每一个医师进行形成性及总结性评价，形成统计数据及分析结论，
                            帮助医院全面提升住院医师规范化培训的质量和水平。</span>
                            <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)}>
                                <span className="text_124">申请试用</span>
                            </div>
                        </div>
                    </div>
                    <div className='group_54 flex-col group_541'>
                        <span className="text_123 flex-col">通过对规范化培训中医师的全过程管理，
                        能够智能化的对每一个医师进行形成性及总结性评价，形成统计数据及分析结论，
                        帮助医院全面提升住院医师规范化培训的质量和水平。</span>
                        <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)}>
                            <span className="text_124">申请试用</span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div className='vw_with group_160 group_22'>
            <div className='w1200 j-w-center'>
                <div className='h50'/>
                <h1 className='wapper_title'>
                    医学模拟中心管理系统
                </h1>
                <p className='wapper_text'>
                    Medical Simulation Center Management system
                </p>
                <section className='section_201 j-w-h-between' id='section-medical'>
                    <div className={`box_12 flex-col box_202 ${domList.indexOf(currtentDom)==1?'up':'down'}`}>
                        <Wave></Wave>
                        <div className='group_54 flex-col'>
                            <span className="text_123 flex-col">针对医学模拟中心日常运营所涉及的“人”，“事”，
                            “物”提供全过程精细化管理协助的信息化系统。同时提供了丰富的管理端统计与报表，
                            让管理人员对模拟中心的工作一目了然。</span>
                            <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)} onClick={() => setModalVisible(true)}>
                                <span className="text_124">申请试用</span>
                            </div>
                        </div>
                    </div>
                    <div className='box_11 flex-col box_201'></div>
                    <div className='group_54 flex-col group_541'>
                        <span className="text_123 flex-col">针对医学模拟中心日常运营所涉及的“人”，“事”，
                        “物”提供全过程精细化管理协助的信息化系统。同时提供了丰富的管理端统计与报表，
                        让管理人员对模拟中心的工作一目了然。</span>
                        <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)} onClick={() => setModalVisible(true)}>
                            <span className="text_124">申请试用</span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div className='vw_with group_160 group_23'>
            <div className='w1200 j-w-center'>
                <div className='h50' />
                <h1 className='wapper_title'>
                    OSCE考试系统
                </h1>
                <p className='wapper_text'>
                    Objective Structured Clinical Examination
                </p>
                <section className='section_201 j-w-h-between' id='section-object'>
                    <div className='block_130 flex-col justify-between'>
                        <div className='block_22 flex-row block_201'>
                            <div className="text-group_235 flex-col">
                                <span className="text_74">全面性</span>
                                <span className="text_75">适用于各种规模考核场景</span>
                            </div>
                        </div>
                        <div className='block_22 flex-row block_202'>
                            <div className="text-group_235 flex-col">
                                <span className="text_74">权威性</span>
                                <span className="text_75">使用国家卫健委及各省最新考核标准 </span>
                            </div>
                        </div>
                        <div className='block_22 flex-row block_203'>
                            <div className="text-group_235 flex-col">
                                <span className="text_74">先进性</span>
                                <span className="text_75">智能化考试规划，电子引导，人脸识别</span>
                            </div>
                        </div>
                        <div className='block_22 flex-row block_204'>
                            <div className="text-group_235 flex-col">
                                <span className="text_74">信息化</span>
                                <span className="text_75">考试全流程无纸化，适配所有题型</span>
                            </div>
                        </div>
                    </div>
                    <div className={`box_12 flex-col box_302 ${domList.indexOf(currtentDom)==2?'up':'down'}`}>
                        <Wave></Wave>
                        <div className='group_54 flex-col'>
                            <span className="text_123 flex-col">通过吸取国内外先进客观结构化临床考试（OSCE）经验，
                            针对国内市场特色，开发了九划OSCE考试系统。通过采用多站式考核方式，
                            采用标准化病人、虚拟病人、计算机等手段，公正、客观地评价应试者的临床技能。</span>
                            <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)}>
                                <span className="text_124">申请试用</span>
                            </div>
                        </div>
                    </div>
                    <div className='group_54 flex-col group_541'>
                        <span className="text_123 flex-col">通过吸取国内外先进客观结构化临床考试（OSCE）经验，
                        针对国内市场特色，开发了九划OSCE考试系统。通过采用多站式考核方式，
                        采用标准化病人、虚拟病人、计算机等手段，公正、客观地评价应试者的临床技能。</span>
                        <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)}>
                            <span className="text_124">申请试用</span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div className='vw_with group_160 group_24'>
            <div className='w1280 j-w-center'>
                <div className='h50' />
                <h1 className='wapper_title'>
                    医学竞赛系统
                </h1>
                <p className='wapper_text'>
                    Medical competition system
                </p>
                <section className='section_201 j-w-h-between' id='section-compet'>
                    <div className={`box_12 flex-col box_402 ${domList.indexOf(currtentDom)==3?'up':'down'}`} >
                        <Wave></Wave>
                        <div className='group_54 flex-col'>
                            <span className="text_123 flex-col">解决医学竞赛主办方对技能竞赛“如何办、怎么办、办精彩”的核心痛点。
                            为医学竞赛赛事提供全方位的策划服务，提供命题审题，
                            模具准备，赛场规划，赛程管理，评分审核，
                            比赛仲裁等与赛事流程相关的全程信息化支持,提供专业的现场拍摄，赛事包装，赛事转播。</span>
                            <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)}>
                                <span className="text_124">申请试用</span>
                            </div>
                        </div>
                    </div>
                    <div className='block_131 flex-col'>
                        <div className='block_32 flex-row block_301'>
                            <div className="text-group_335 flex-col">
                                <span className="text_74">公平</span>
                                <span className="text_75">赛事顺序运用电子抽签，考官与学员随机分配</span>
                            </div>
                        </div>
                        <div className='block_32 flex-row block_302'>
                            <div className="text-group_335 flex-col">
                                <span className="text_74">公开</span>
                                <span className="text_75">赛事顺序运用电子抽签，考官与学员随机分配 </span>
                            </div>
                        </div>
                        <div className='block_32 flex-row block_303'>
                            <div className="text-group_335 flex-col">
                                <span className="text_74">公正</span>
                                <span className="text_75">题干与分数实时公布，统一赛事点评系统</span>
                            </div>
                        </div>
                    </div>
                    <div className='group_54 flex-col group_541'>
                        <span className="text_123 flex-col">解决医学竞赛主办方对技能竞赛“如何办、怎么办、办精彩”的核心痛点。
                        为医学竞赛赛事提供全方位的策划服务，提供命题审题，
                        模具准备，赛场规划，赛程管理，评分审核，
                        比赛仲裁等与赛事流程相关的全程信息化支持,提供专业的现场拍摄，赛事包装，赛事转播。</span>
                        <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)}>
                            <span className="text_124">申请试用</span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div className='vw_with group_160 group_25'>
            <div className='w1200 j-w-center'>
            <div className='h50' />
                <h1 className='wapper_title'>
                    医学云课堂
                </h1>
                <p className='wapper_text'>
                    Medical Cloud Classroom
                </p>
                <section className='section_201 j-w-h-between' id='section-cloud'>
                    <div className='block_132 flex-col'>
                        <div className='block_42 flex-row block_401'>
                            <div className="text-group_235 flex-col">
                                <span className="text_76">高质量多元化专业课程</span>
                            </div>
                        </div>
                        <div className='block_42 flex-row block_402'>
                            <div className="text-group_235 flex-col">
                                <span className="text_76">全过程实时质量监控体系</span>
                            </div>
                        </div>
                        <div className='block_42 flex-row block_403'>
                            <div className="text-group_235 flex-col">
                                <span className="text_76">高水平骨干师资授课团队</span>
                            </div>
                        </div>
                    </div>
                    <div className={`box_12 flex-col box_502 ${domList.indexOf(currtentDom)==4?'up':'down'}`}>
                        <Wave></Wave>
                        <div className='group_54 flex-col'>
                            <span className="text_123 flex-col">集合优质继续医学教育资源，运用开放性办学模式构建的继续医学教育服务云平台。
                            与浙大二院、浙江医院、湘雅三院、南方医科大学珠江医院等全国知名医学院校和省级三甲医院达成战略合作，
                            提供从课程学习到评价考核的完整技术支持。</span>
                            <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)}>
                                <span className="text_124">申请试用</span>
                            </div>
                        </div>
                    </div>
                    <div className='group_54 flex-col group_541'>
                        <span className="text_123 flex-col">集合优质继续医学教育资源，运用开放性办学模式构建的继续医学教育服务云平台。
                        与浙大二院、浙江医院、湘雅三院、南方医科大学珠江医院等全国知名医学院校和省级三甲医院达成战略合作，
                        提供从课程学习到评价考核的完整技术支持。</span>
                        <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)}>
                            <span className="text_124">申请试用</span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div className='vw_with group_160 group_26 flex-col'>
            <div className='w1200 j-w-center'>
                <div className='h50' />
                <h1 className='wapper_title'>
                    临床思维训练系统
                </h1>
                <p className='wapper_text'>
                    Clinical reasoning training
                </p>
                <section className='section_301 j-w-h-between' id='section-clinical'>
                    <div className={`box_12 flex-col box_602 ${domList.indexOf(currtentDom)==5?'up':'down'}`}>
                        <Wave></Wave>
                        <div className='group_54 flex-col'>
                            <span className="text_123 flex-col">通过场景化虚拟人的方式模拟医学临床诊疗过程，
                            以零风险、低成本、高效率的模式，训练医学生、
                            低年资的住院医师以及基层医生在临床工作中主动分析问题、处理问题的能力，提高他们的临床诊疗能力。</span>
                            <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)}>
                                <span className="text_124">申请试用</span>
                            </div>
                        </div>
                    </div>
                    <div className='block_133 flex-col justify-between'>
                        <div className='block_52 flex-row block_501'>
                            <div className="text-group_435 flex-col">
                                <span className="text_76">临床思维提纲精品化</span>
                            </div>
                        </div>
                        <div className='block_52 flex-row block_502'>
                            <div className="text-group_435 flex-col">
                                <span className="text_76">病例创建转化高效化</span>
                            </div>
                        </div>
                        <div className='block_52 flex-row block_503'>
                            <div className="text-group_435 flex-col">
                                <span className="text_76">练习考核模式多样化</span>
                            </div>
                        </div>
                        <div className='block_52 flex-row block_504'>
                            <div className="text-group_435 flex-col">
                                <span className="text_76">指导反馈质量规范化</span>
                            </div>
                        </div>
                    </div>
                    <div className='group_54 flex-col group_541'>
                        <span className="text_123 flex-col">通过场景化虚拟人的方式模拟医学临床诊疗过程，
                        以零风险、低成本、高效率的模式，训练医学生、
                        低年资的住院医师以及基层医生在临床工作中主动分析问题、处理问题的能力，提高他们的临床诊疗能力。</span>
                        <div className="text-wrapper_42 flex-col" onClick={() => setModalVisible(true)}>
                            <span className="text_124">申请试用</span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <Modal {...modalConfig}>{modalChildren}</Modal>
    </section>
}

export default SystemCom;