import React, { useState, useEffect,useContext } from 'react';
import { GlobalContext } from './globalContext';
import { scrollToEl, viewIs, debounce } from './common/utils'
import Header from './component/header';
import Top from './component/top';
import Honor from './component/HonorComponent';
import Product from './component/ProductComponent';
import Footer from './component/footer';
import './App.css';

function App() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currtentDom, setCurrtentDom] = useState('section-top');
  const domList = ['top','honor','product','resident','medical','object','compet','cloud','clinical','footer']
  const handleScroll = () => {
      const position = window.pageYOffset;
      let statusCur = 0
      domList.forEach(el=>{
        if(viewIs('section-'+el)){
          statusCur = el
          setCurrtentDom(statusCur)
        }
      })
      setScrollPosition(position);
      const topVideo = document.querySelector('#top-video')
      const replayVideo = document.querySelector('#replay-video')
      if(!viewIs('section-top')&&topVideo){
        topVideo.pause()
      }
      if(!viewIs('replay-video')&&replayVideo){
        replayVideo.pause()
      }
  };

  useEffect(() => {
      window.addEventListener('scroll', debounce(handleScroll, 50), { passive: true });
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);
  return (
    <GlobalContext.Provider value={{scrollPosition,currtentDom}}>
      <div className="App">
        <Header {...{scrollPosition}}/>
        <Top/>
        <div className='main flex-col'>
          <Honor/>
          <Product/>
        </div>
        <Footer/>
      </div>
      {
        domList.indexOf(currtentDom)>1&&<div className="group_13 flex-row justify-center" onClick={()=>{
          scrollToEl('section-top')
        }}>
          <div className="image-text_120 flex-col justify-between">
            <div className="box_15 flex-col"></div>
            <span className="text-group_13">回到顶部</span>
          </div>
        </div>
      }
    </GlobalContext.Provider>
  );
}

export default App;
