import { useState, useEffect } from 'react';
import img1 from '../../images/honor/card/part3-image1.jpg'
import img2 from '../../images/honor/card/part3-image2.jpg'
import img3 from '../../images/honor/card/part3-image3.jpg'
import img4 from '../../images/honor/card/part3-image4.jpg'
import img5 from '../../images/honor/card/part3-image5.jpg'
import img6 from '../../images/honor/card/part3-image6.jpg'
import img7 from '../../images/honor/card/part3-image7.jpg'
import img8 from '../../images/honor/card/part3-image8.jpg'
import { Pagination } from "swiper";
import { Swiper, SwiperSlide  } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';


const HonorCard = () => {
   
    const [activeIndex, setActiveIndex] = useState(0);
    const cardOjb = [
        {
            img:img1,
            text1:'海南省第四届',
            text2:'住院医师临床技能大赛',
            time:'2021/10/23'
        },
        {
            img:img2,
            text1:'湖南省医师协会',
            text2:'全科医师分会成立大会',
            time:'2022/07/08'
        },
        {
            img:img3,
            text1:'2022年贵州首届',
            text2:'全省住院医师临床技能大赛',
            time:'2022/07/21'
        },
        {
            img:img4,
            text1:'第四届广西住培',
            text2:'(助理全科)临床技能大赛',
            time:'2021/12/08'
        },
        {
            img:img5,
            text1:'2022年浙江省',
            text2:'住院医师规范化培训结业考核',
            time:'2022/05/27'
        },
        {
            img:img6,
            text1:'2022年海南省',
            text2:'住院医师规范化培训结业考核',
            time:'2022/05/27'
        },
        {
            img:img7,
            text1:'广西壮族自治区',
            text2:'住培考官考核能力提升培训班',
            time:'2021/05/08'
        },
        {
            img:img8,
            text1:'全国住培临床',
            text2:'实践能力考核考务线上培训班',
            time:'2022/04/09'
        }
    ]
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"> </span>';
        },
    };
    return <div className='w1200 card_swiper'>
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            initialSlide={activeIndex}
            pagination={pagination} 
            modules={[Pagination]}
            onSlideChange={(swipe) => setActiveIndex(swipe.activeIndex)}
            // onSwiper={(swiper) => console.log(swiper)}
        >
            <SwiperSlide>
                <div className='list_wapper flex-wrap' >
                    {
                        cardOjb.map((card,index)=>{
                            if(index>3)return null
                            return<div className='list_card j-flex-col' key={index}>
                                <img className='card_top' src={card.img} />
                                <p className='card_text'>{card.text1}</p>
                                <p className='card_text'>{card.text2}</p>
                                <div className='card_time justify-between'>
                                    <span className='text_4'>{card.time}</span>
                                    <span className='text_3'>”</span>
                                </div>
                            </div>
                        })
                    }
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='list_wapper flex-wrap'>
                    {
                        cardOjb.map((card,index)=>{
                            if(index<4)return null
                            return<div className='list_card j-flex-col' key={index}>
                                <img className='card_top' src={card.img} />
                                <p className='card_text'>{card.text1}</p>
                                <p className='card_text'>{card.text2}</p>
                                <div className='card_time justify-between'>
                                    <span className='text_4'>{card.time}</span>
                                    <span className='text_3'>”</span>
                                </div>
                            </div>
                        })
                    }
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
}

export default HonorCard;