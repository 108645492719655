
import SystemCom from './SystemCom'
import LeftNav from './LeftNav'
import { scrollToEl } from '../../common/utils'
import part4title from '../../images/part4-title.png'

import "../../css/product.css";
const Product = () => {
    
    const domList = ['resident','medical','object','compet','cloud','clinical']
    const changeTab = (i)=>{
        scrollToEl(`section-${domList[i]}`)
    }
    return <section className='honor-wapper'>
        <LeftNav></LeftNav>
        <div className='vw_with'>
            <div className='w1200 j-w-center section_901' id='section-product'>
                <div className='h50'/>
                <img className='part4_title' src={part4title} />
                <section className='section_101 flex-row'>
                    <div className='group_11 flex-col group_101' onClick={()=>changeTab(0)}>
                        <p className="text_11">住院医师规范化管理系统</p>
                    </div>
                    <div className='group_11 flex-col group_102' onClick={()=>changeTab(1)}>
                        <p className="text_11">医学模拟中心管理系统</p>
                    </div>
                    <div className='group_11 flex-col group_103' onClick={()=>changeTab(2)}>
                        <p className="text_11">OSCE考试系统</p>
                    </div>
                </section>
                <section className='flex-row'>
                    <div className='group_11 flex-col group_104' onClick={()=>changeTab(3)}>
                        <p className="text_11">医学竞赛系统</p>
                    </div>
                    <div className='group_11 flex-col group_105' onClick={()=>changeTab(4)}>
                        <p className="text_11">医学云课堂</p>
                    </div>
                    <div className='group_11 flex-col group_106' onClick={()=>changeTab(5)}>
                        <p className="text_11">临床思维训练系统</p>
                    </div>
                </section>
            </div>
            <SystemCom></SystemCom>
        </div>
    </section>
}

export default Product;