import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../../globalContext';
import CountUp from 'react-countup'

let isFirst = false

const HonorCount = () => {
    const [isHonor,setHonor] = useState(true)
    const options = useContext(GlobalContext);
    const { currtentDom } = options;

    useEffect(()=>{
        if(currtentDom=='honor'&&!isFirst){
            isFirst = true
            setHonor(true)
        }else{
            setHonor(false)
        }
    })
    const countObj = [{
        count:'18',
        text_1:'场',
        text_2:'成功举办竞赛',
        decimals:null,
        duration:'10'
    },
    {
        count:'400',
        text_1:'+',
        text_2:'服务住培基地',
        decimals:null,
        duration:'10'
    },
    {
        count:'223.5',
        text_1:'万',
        text_2:'总观看人数',
        decimals:'1',
        duration:'10'
    }]
    const countObj2 = [
        {
            count:'32',
            text_1:'场',
            text_2:'开展课程培训',
            decimals:null,
            duration:'10'
        },
        {
            count:'1300',
            text_1:'+',
            text_2:'拥有专业讲师',
            decimals:null,
            duration:'10'
        },
        {
            count:'12000',
            text_1:'+',
            text_2:'累计服务学员',
            decimals:null,
            duration:'8'
        }
    ]
    const countObj3 = [
    {
        count:'10',
        text_1:'+',
        text_2:'支持考点',
        decimals:null,
        duration:'8'
    },
    {
        count:'4000',
        text_1:'+',
        text_2:'累计服务选手',
        decimals:null,
        duration:'8'
    },
    {
        count:'20000',
        text_1:'+',
        text_2:'累计服务考生',
        decimals:null,
        duration:'6'
    }]
    return <section className='flex-col align-center'>
        <div className='group_count flex-wrap align-center justify-start'>
            <div className='title_count flex-col'>医学竞赛</div>
            {
                countObj.map((item,i)=>{
                    return (<div className='wapper_count flex-col' key={i}>
                    <div className='count_top j-flex-row'>
                        <CountUp
                            className="count_1"
                            start={0}
                            end={item.count}
                            duration={2}
                            useEasing={false}
                            redraw={isHonor}
                            decimals={item.decimals}
                        />
                        <span className='text_1'>{item.text_1}</span>
                    </div>
                    <div className='text_2'>{item.text_2}</div>
                </div>)
                })
            }
        </div>
        <div className='group_count flex-wrap align-center justify-start'>
            <div className='title_count flex-col'>培训课程</div>
            {
                countObj2.map((item,i)=>{
                    return (<div className='wapper_count flex-col' key={i}>
                    <div className='count_top j-flex-row'>
                        <CountUp
                            className="count_1"
                            start={0}
                            end={item.count}
                            duration={2}
                            useEasing={false}
                            redraw={isHonor}
                            decimals={item.decimals}
                        />
                        <span className='text_1'>{item.text_1}</span>
                    </div>
                    <div className='text_2'>{item.text_2}</div>
                </div>)
                })
            }
        </div>
        <div className='group_count flex-wrap align-center justify-start'>
            <div className='title_count flex-col'>结业考核</div>
            {
                countObj3.map((item,i)=>{
                    return (<div className='wapper_count flex-col' key={i}>
                    <div className='count_top j-flex-row'>
                        <CountUp
                            className="count_1"
                            start={0}
                            end={item.count}
                            duration={2}
                            useEasing={false}
                            redraw={isHonor}
                            decimals={item.decimals}
                        />
                        <span className='text_1'>{item.text_1}</span>
                    </div>
                    <div className='text_2'>{item.text_2}</div>
                </div>)
                })
            }
        </div>
    </section>
}

export default HonorCount;